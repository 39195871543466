import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDrawerDispatch, useDrawerState } from 'context/DrawerContext'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import Button, { KIND, SIZE } from 'components/Button/Button'
import {
  ButtonGroup,
  DrawerTitle,
  DrawerTitleWrapper,
  FieldDetails,
  Form,
  RowTitle,
} from 'containers/DrawerItems/DrawerItems.style'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { Col, Flex, Grid, Row } from 'components/FlexBox/FlexBox'
import DrawerBox from 'components/DrawerBox/DrawerBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'
import Input from 'components/Input/Input'
import { Label4 } from 'baseui/typography'
import { Scrollbars } from 'react-custom-scrollbars'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { ChromePicker } from 'react-color'
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox'
import { ColorCircleBig } from 'containers/Colors/Colors.style'
import { ColorPicker } from 'components/ColorGradientPicker'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SiteLanguageVar } from 'lib/reactiveVars'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { removeKey } from 'utils'
import { DURATION, SnackbarProvider, useSnackbar } from 'baseui/snackbar'
import { ArrowLeft, Check } from 'baseui/icon'
import ColorProfileInput from 'components/LanguageProfiles/ColorProfileInput'
import ManufacturerProfileInput from 'components/LanguageProfiles/ManufacturerProfileInput'
import Uploader from 'components/Uploader/Uploader'
import ShopProfileInput from 'components/LanguageProfiles/ShopProfileInput'
import DefaultPaymentMethodSelect from 'components/PaymentMethodSelect/DefaultPaymentMethodSelect'
import DefaultShippingMethodSelect from 'components/ShippingMethodSelect/DefaultShippingMethodSelect'
import StyleSelect from 'components/StyleSelect/StyleSelect'
import CollectionsSelect from 'components/CollectionsSelect/CollectionsSelect'
import ParentCategorySelect from 'components/CategorySelect/ParentCategorySelect'
import DrawerSelect from 'components/Select/DrawerSelect'
import UploaderBanners from 'components/Uploader/UploaderBanners'
import PolicyProfileInput from 'components/LanguageProfiles/PolicyProfileInput'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import LanguageSettings from './LanguageSettingsForm'
import { SITE_SETTINGS } from 'settings/constants'
import { useHistory } from 'react-router-dom'
import { Notification, KIND as KINDNOTIFICATION } from 'baseui/notification'
import { useDrawerLeftDispatch } from 'context/DrawerLeftContext'
import ContentBlockSelect from 'components/ContentBlockSelect/ContentBlockSelect'
import HomePageCardsHandler from 'components/ShopSettings/HomePageCardsHandler'
import { id } from 'date-fns/locale'
import PopUpProfileInput from 'components/LanguageProfiles/PopUpProfileInput'

// TODO Add currency change! Backend / Frontend

export const CORE_HOME_PAGE_DATA = gql`
  fragment CoreHomePageData on ShopSetting {
    id
    # === PopUp settings
    popup_enabled
    popup_time_delay
    popup_discount_code
    popup_language_profiles {
      id
      title
      button_text
      image {
        src
      }
      language
    }

    have_you_found_cheaper_popup_enabled
  }
`

export const UPDATE_POPUP_SETTNGS = gql`
  ${CORE_HOME_PAGE_DATA}
  mutation UPDATE_POPUP_SETTNGS(
    $shopSettingsId: Int
    $popup_enabled: Boolean!
    $popup_time_delay: Int!
    $popup_discount_code: String
    $popup_language_profiles: [PopUpProfileCustomInput]
    $have_you_found_cheaper_popup_enabled: Boolean!
  ) {
    updatePopUpSettings(
      shopSettingsId: $shopSettingsId
      popup_enabled: $popup_enabled
      popup_time_delay: $popup_time_delay
      popup_discount_code: $popup_discount_code
      popup_language_profiles: $popup_language_profiles
      have_you_found_cheaper_popup_enabled: $have_you_found_cheaper_popup_enabled
    ) {
      ...CoreHomePageData
    }
  }
`

// We only get the first shop settings, then we update it or create a new if its null.
export const GET_POPUP_SETTINGS = gql`
  ${CORE_HOME_PAGE_DATA}
  query GET_POPUP_SETTINGS {
    findFirstShopSetting {
      ...CoreHomePageData
    }
  }
`

// Validation rules....
const schema = yup.object().shape({
  // ====
})

type Props = any
const PopUpSettingsForm: React.FC<Props> = () => {
  // const data = useDrawerState("data");
  // const id = data.postID;

  const { enqueue } = useSnackbar()

  //* ------------------------ DRAWER CONTEXT
  const dispatch = useDrawerDispatch()
  // ===== CLOSE DRAWER CALLBACK
  const closeDrawer = useCallback(
    () => dispatch({ type: 'CLOSE_DRAWER' }),
    [dispatch],
  )

  //* ------------------------ DRAWER LEFT CONTEXT
  const dispatchLeft = useDrawerLeftDispatch()

  const openAddNewContentBlockForm = useCallback(() => {
    dispatchLeft({
      type: 'OPEN_LEFT_DRAWER',
      drawerComponent: 'CONTENT_BLOCK_FORM',
      // We pass the reactive Var, we want the results to be stored...
      data: {
        // id: id,
        drawer_left: true,
      },
    })
  }, [dispatchLeft])

  //* ==== Get all the posts data needed by passing the "data.postID" retrieved from Context
  const {
    data: queryData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(GET_POPUP_SETTINGS)

  //* ==== Mutation to edita data in database
  const [
    updatePopUpSettings,
    {
      data: mutationData,
      called,
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(UPDATE_POPUP_SETTNGS, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)

      enqueue(
        {
          message: 'Error!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.red400,
              }),
            },
          },
        },
        DURATION.short,
      )

      // After mutation is finished - show the updated data...
      // ======== If I need to trigger an error in the profiles tab...
      // setError("profiles", {
      //   type: "manual",
      //   message: "Dont Forget Your Username Should Be Cool!",
      // });
    },
    onCompleted: (e) => {
      // Handle the success case.
      enqueue(
        {
          message: 'Saving succesful!',
          startEnhancer: ({ size }) => <Check size={size} />,
          overrides: {
            Message: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
            StartEnhancerContainer: {
              style: ({ $theme }) => ({
                color: $theme.colors.lightGreen,
              }),
            },
          },
        },
        DURATION.short,
      )
    },
    refetchQueries: ['GET_POPUP_SETTINGS'],
  })

  //=========== REACT HOOOK FORM ============
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      shopSettingsId: null,
      //---
      popup_enabled: true,
      popup_time_delay: 30,
      popup_discount_code: '',
      popup_language_profiles: [],
      have_you_found_cheaper_popup_enabled: true,
    },
  })

  // =================================

  useEffect(() => {
    // Update Hex in state, once Graphql is finished
    if (queryData?.findFirstShopSetting) {
      console.log('queryData ->', queryData)
      setValue('shopSettingsId', queryData.findFirstShopSetting.id ?? null)
      setValue(
        'popup_enabled',
        queryData.findFirstShopSetting.popup_enabled ?? false,
      )

      setValue(
        'have_you_found_cheaper_popup_enabled',
        queryData.findFirstShopSetting.have_you_found_cheaper_popup_enabled ??
          false,
      )
      setValue(
        'popup_time_delay',
        queryData.findFirstShopSetting.popup_time_delay ?? null,
      )
      setValue(
        'popup_discount_code',
        queryData.findFirstShopSetting.popup_discount_code ?? null,
      )
      const modifiedPopUpProfiles =
        queryData?.findFirstShopSetting?.popup_language_profiles?.map(
          (popUpProfile) => ({
            ...popUpProfile,
            image: popUpProfile.image ? [popUpProfile.image.src] : [], // Replace image object with src value or empty array
          }),
        )
      setValue(
        'popup_language_profiles',
        queryData?.findFirstShopSetting?.popup_language_profiles.length
          ? modifiedPopUpProfiles
          : [],
      )
    }
  }, [setValue, queryData]) // We need to include called value (to refresh after mutation)

  const onSubmit = (data) => {
    console.log('Submitting -->', data)

    // // Remove __typename keys from the query.
    const modifiedData = removeKey(data, '__typename')
    // ===================================
    updatePopUpSettings({
      variables: {
        shopSettingsId: modifiedData.shopSettingsId,
        popup_enabled: modifiedData.popup_enabled,
        popup_time_delay: parseInt(modifiedData.popup_time_delay),
        popup_discount_code: modifiedData.popup_discount_code,
        popup_language_profiles: modifiedData.popup_language_profiles,
        have_you_found_cheaper_popup_enabled:
          modifiedData.have_you_found_cheaper_popup_enabled,
      },
    })
  }

  const isEnabled = useWatch({
    control,
    name: 'popup_enabled',
    defaultValue: false,
  })

  const isHaveYouFoundCheaperEnabled = useWatch({
    control,
    name: 'have_you_found_cheaper_popup_enabled',
    defaultValue: false,
  })

  let history = useHistory()
  // const myRef = useRef(null)
  // const executeScroll = () => myRef.current.scrollIntoView()

  // if (mutationError) {
  //   setTimeout(() => {
  //     executeScroll()
  //   }, 1000)
  // }

  //* ===== always manage the loading state, or error will display "TypeError: Cannot read property 'productContainer' of undefined"
  if (loading) return <InLineLoader />
  if (error) return <p>Query Error! {error.message}</p>

  return (
    <>
      <Button
        // shape={SHAPE.circle}
        // startEnhancer={() => <ArrowLeft size={20} />}
        onClick={() => history.push(SITE_SETTINGS)}
        overrides={{
          BaseButton: {
            style: {
              padding: '0 10px',
              marginBottom: '10px',
            },
          },
        }}
      >
        <ArrowLeft size={40} />
      </Button>
      <br></br>
      <DrawerTitle>Popup Settings</DrawerTitle>
      {Object.keys(errors).length > 0 ? (
        <Label4 color="red">There are ERRORS! (scroll up or down)</Label4>
      ) : null}

      <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        {/* ==============  Have you found cheaper POPUP SETTINGS ============== */}
        <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>
                Have you found cheaper price function (PRODUCT PAGE)
              </FormLabel>
              <FieldDetails style={{ padding: 0 }}>
                You can enable a have you found cheaper functionality, that will
                apear as a button in the product page above the add cart button.
                This button will ask users if they found a cheaper product than
                in your website and will save the users response near the
                customer data.
              </FieldDetails>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              <Row>
                <Col lg={9} sm={12}>
                  <FieldDetails style={{ padding: 0 }}>
                    Enable have you found cheaper popup?
                    <strong>
                      {isHaveYouFoundCheaperEnabled ? 'Yes' : 'No'}
                    </strong>
                  </FieldDetails>
                </Col>

                <Col lg={3} sm={12}>
                  <FormFields>
                    <Controller
                      name="have_you_found_cheaper_popup_enabled"
                      control={control}
                      rules={{
                        required: true,
                        shouldUnregister: true,
                      }}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={field.onChange}
                          labelPlacement={LABEL_PLACEMENT.left}
                          overrides={{
                            Root: {
                              style: {
                                float: 'right',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </FormFields>
                </Col>
              </Row>
            </DrawerBox>
          </Col>
        </Row>

        {/* ==============  POPUP SETTINGS ============== */}
        <Row>
          <Col lg={3} sm={12}>
            <DrawerBox sameHeight noBackground>
              <FormLabel>
                Popup function (EVERYWHERE ON THE WEBSITE, but appears only
                ONCE)
              </FormLabel>
              <FieldDetails style={{ padding: 0 }}>
                You can add create a popup, that would appear for first time
                visitors! The popup will have an email filed for the user to
                enter his email, and a discount code will be sent to that email.
              </FieldDetails>
            </DrawerBox>
          </Col>
          <Col lg={9} sm={12}>
            <DrawerBox sameHeight>
              <Row>
                <Col lg={9} sm={12}>
                  <FieldDetails style={{ padding: 0 }}>
                    Enable popup?<strong> {isEnabled ? 'Yes' : 'No'}</strong>
                  </FieldDetails>
                </Col>

                <Col lg={3} sm={12}>
                  <FormFields>
                    <Controller
                      name="popup_enabled"
                      control={control}
                      rules={{
                        required: true,
                        shouldUnregister: true,
                      }}
                      render={({ field }) => (
                        <Checkbox
                          checked={field.value}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={field.onChange}
                          labelPlacement={LABEL_PLACEMENT.left}
                          overrides={{
                            Root: {
                              style: {
                                float: 'right',
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </FormFields>
                </Col>
              </Row>

              <FormFields>
                <FormLabel>Popup time delay</FormLabel>
                <Input
                  type="number"
                  {...register('popup_time_delay')}
                  error={errors.popup_time_delay ? true : false}
                />
                {errors.popup_time_delay && (
                  <Label4 color="red">{errors.popup_time_delay.message}</Label4>
                )}
              </FormFields>
              <FormFields>
                <FormLabel>Popup discount code (sent by email)</FormLabel>
                <Input
                  {...register('popup_discount_code')}
                  error={errors.popup_discount_code ? true : false}
                />
                {errors.popup_discount_code && (
                  <Label4 color="red">
                    {errors.popup_discount_code.message}
                  </Label4>
                )}
              </FormFields>

              {/* ============== PROFILE (LANGUGAGE) EDIT ============== */}
              <Controller
                name="popup_language_profiles"
                control={control}
                rules={{
                  shouldUnregister: true,
                }}
                render={({ field }) => {
                  return (
                    <ProfileInputHandler
                      value={field.value}
                      onChange={field.onChange}
                      errors={errors.popup_language_profiles}
                    />
                  )
                }}
              />

              <div style={{ marginBottom: 200 }}></div>
            </DrawerBox>
          </Col>
        </Row>

        {/* ============== SUBMIT BUTTONS ============== */}
        <Button
          type="submit"
          // disabled={mutationLoading}
          isLoading={mutationLoading}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: '90%',
                margin: '15px 15px 15px 15px',
                // marginTop: "15px",
                // marginBottom: "15px",
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
                borderBottomLeftRadius: '3px',
              }),
            },
          }}
        >
          Save
        </Button>
      </Form>
    </>
  )
}

//  ========= ( Language Profiles ) ===========================
const ProfileInputHandler = (props) => {
  // This component - to handle information coming from the inputs
  // And pass it up using the props.Onchange function.
  const updateAllProfiles = props.onChange
  let allLanguageProfiles = props.value
  const errors = props.errors ? props.errors : null

  // We need to loop all profiles to match the default site language.
  const siteLanguage = useReactiveVar(SiteLanguageVar)
  const [lang, setLang] = useState(siteLanguage)
  const selectedProfile = allLanguageProfiles.filter(
    (profile) => profile.language === lang,
  )[0]

  const currentProfileOnChange = (profile) => {
    // The returned current profile includes all the fields and the current language.
    // We need to modify the allLanguageProfiles value, to include the updated profiles.
    // We need to search and replace the object if the language exists.
    // We need to add a new object if language does not exist in the array.

    let newProfilesList = [...allLanguageProfiles]
    const indexOfLanguageProfile = newProfilesList.findIndex(
      (item) => item.language === lang,
    )

    if (indexOfLanguageProfile > -1) {
      // Means there is a profile with this language.
      newProfilesList[indexOfLanguageProfile] = profile
    } else {
      // profile with this lang does not exist, push to list.
      if (!profile.id && !profile.title) {
        // We skip the push...
      } else {
        newProfilesList.push(profile)
      }
    }

    updateAllProfiles(newProfilesList)
  }

  return (
    <PopUpProfileInput
      selectedProfile={selectedProfile}
      allLanguageProfiles={allLanguageProfiles}
      lang={lang}
      setLang={setLang}
      currentProfileOnChange={currentProfileOnChange}
      errors={errors}
    />
  )
}

export default PopUpSettingsForm
