import { gql, useLazyQuery } from '@apollo/client'
import { LoginErrorVar, LoginSuccessVar } from 'lib/reactiveVars'
import React, { useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'

type AuthProps = {
  isAuthenticated: boolean
  authenticate: Function
  demoAuthenticate: Function
  signout: Function
  user: any
}

export const LOGIN_QUERY = gql`
  query LOGIN_QUERY($email: String!, $password: String!) {
    loginQuery(email: $email, password: $password)
  }
`

export const DEMO_LOGIN_QUERY = gql`
  query DEMO_LOGIN_QUERY(
    $name: String!
    $email: String!
    $phone: String
    $privacyPolicy: Boolean!
    $username: String!
    $password: String!
  ) {
    demoLoginQuery(
      name: $name
      email: $email
      phone: $phone
      privacyPolicy: $privacyPolicy
      username: $username
      password: $password
    )
  }
`
export const AuthContext = React.createContext({} as AuthProps)
const TOKEN_KEY = 'furnisystems_token'

const getUser = (token) => {
  if (token) {
    try {
      const decodedToken: any = jwtDecode(token)
      const currentTime = Date.now() / 1000

      if (decodedToken.exp < currentTime) {
        console.error('Token expired!')
        localStorage.removeItem(TOKEN_KEY)
        return null
      } else {
        console.log('Token is valid!', decodedToken)
        return {
          email: decodedToken.email,
          id: decodedToken.id,
          exp: decodedToken.exp,
        }
      }
    } catch (e) {
      console.error('Invalid token:', e)
      return null
    }
  }
  return null
}

const AuthProvider = (props: any) => {
  const [user, setUser] = React.useState(null)

  //const [isAuthenticated, makeAuthenticated] = React.useState(user !== null)

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY)
    const user = getUser(token)
    setUser(user)

    const checkTokenExpiry = () => {
      const token = localStorage.getItem(TOKEN_KEY)
      if (token) {
        const decodedToken: any = jwtDecode(token)
        const currentTime = Date.now() / 1000

        if (decodedToken.exp < currentTime) {
          signout()
          return null
        } else {
          return {
            email: decodedToken.email,
            id: decodedToken.id,
            exp: decodedToken.exp,
          }
        }
      }
    }

    const intervalId = setInterval(checkTokenExpiry, 60000) // Check every minute
    return () => clearInterval(intervalId)
  }, [])

  // === GRAPHQL Query to get LOGIN
  const [loginQueryLazy, { loading, error, data: queryData }] = useLazyQuery(
    LOGIN_QUERY,
    {
      onError: (e) => {
        // For mutation errors...
        console.log('Login error!', e)
        console.log('Extracted error!', e.graphQLErrors)
        LoginErrorVar(true)
        LoginSuccessVar(false)
      },
      onCompleted: (e) => {
        // console.log("Login completed...");
        const token = queryData.loginQuery
        // console.log("We have token ->", token);

        // makeAuthenticated(true)

        const user = getUser(token)

        console.log('User -> login', user, token)
        setUser(user)

        if (user) {
          localStorage.setItem(TOKEN_KEY, `${token}`)
          LoginErrorVar(false)
          LoginSuccessVar(true)
        }
      },
      fetchPolicy: 'network-only',
    },
    // -- Turning off, as referch queries are a lot more efficient
    // fetchPolicy: "cache-and-network",
  )

  // === GRAPHQL Query to get DEMO LOGIN
  const [
    loginDemoQueryLazy,
    { loading: loadingDemo, error: errorDemo, data: queryDemoData },
  ] = useLazyQuery(
    DEMO_LOGIN_QUERY,
    {
      onError: (e) => {
        // For mutation errors...
        console.log('DEMO Login error!', e)
        console.log('Extracted error!', e.graphQLErrors)
        LoginErrorVar(true)
        LoginSuccessVar(false)
      },
      onCompleted: (e) => {
        // console.log("Login completed...");
        const token = queryDemoData.demoLoginQuery
        // console.log("We have token ->", token);

        const user = getUser(token)

        if (user) {
          setUser(user)
          LoginErrorVar(false)
          LoginSuccessVar(true)
          localStorage.setItem(TOKEN_KEY, `${token}`)
        }
      },
      fetchPolicy: 'network-only',
    },
    // -- Turning off, as referch queries are a lot more efficient
  )

  async function authenticate({ username, password }) {
    // Check if username and pass matches agains env
    loginQueryLazy({
      variables: {
        email: username,
        password: password,
      },
    })
  }

  async function demoAuthenticate({
    name,
    email,
    phone,
    privacyPolicy,
    username,
    password,
  }) {
    // Check if username and pass matches agains env
    loginDemoQueryLazy({
      variables: {
        name: name,
        email: email,
        phone: phone,
        privacyPolicy: privacyPolicy,
        username: username,
        password: password,
      },
    })
  }

  function signout() {
    setUser(null)
    LoginSuccessVar(false)
    localStorage.removeItem(TOKEN_KEY)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user !== null,
        authenticate,
        demoAuthenticate,
        user,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  )
}

export default AuthProvider
