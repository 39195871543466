import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { Spinner } from 'baseui/icon'
import { Label3, Label4 } from 'baseui/typography'
import { Row } from 'components/FlexBox/FlexBox'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { EnabledLanguagesInSettingsVar } from 'lib/reactiveVars'
import React from 'react'

export const GET_LANGUAGES = gql`
  query GET_LANGUAGES {
    __type(name: "Language") {
      name
      enumValues {
        name
      }
    }
  }
`

export const GET_SITE_ENABLED_LANGUAGES = gql`
  query GET_SITE_ENABLED_LANGUAGES {
    findFirstShopSetting {
            enabled_languages {
        id
        language
        web_address_enabled
        web_address
        default_language
      }
    }
  }
`

const LanguagesEnabledInProfile = (allLanguageProfiles) => {
  // const { loading, error, data } = useQuery(GET_LANGUAGES)
  const {
    loading: loadingLanguages,
    error: errorLanguages,
    data: dataLanguages,
  } = useQuery(GET_SITE_ENABLED_LANGUAGES)

  const enabledLanguagesInSettings = useReactiveVar(
    EnabledLanguagesInSettingsVar,
  )

  // ==== Arrange the data...
  const enabledLanguages =
    dataLanguages?.findFirstShopSetting?.enabled_languages ?? null
  let arrayOfEnabledLanguages = [] as any
  let defaultLanguageObject
  if (enabledLanguages) {
    for (const enabledLanguage of enabledLanguages) {
      arrayOfEnabledLanguages.push(enabledLanguage.language)
      if (enabledLanguage.default_language) {
        defaultLanguageObject = enabledLanguage
      }
    }
  }

  // ===== Handle Query
  // if (loading) return <InLineLoader />;
  // if (error) return <p>Query Error! {error.message}</p>;
  // =====================
  if (errorLanguages) {
    return <p>Error! {errorLanguages.message}</p>
  }
  if (loadingLanguages) {
    return <InLineLoader />
  }

  // -- Get the list of all available languages
  // const allSiteLanguages = data.__type.enumValues.map((lang) => {
  //   return lang.name;
  // });

  if (arrayOfEnabledLanguages.length === 0) {
    if (enabledLanguagesInSettings.length > 0) {
      for (const eLang of enabledLanguagesInSettings) {
        arrayOfEnabledLanguages.push(eLang.language)
      }
    } else {
      // Maybe push EN if no selected?
      // arrayOfEnabledLanguages.push('en')
    }
  }

  const allSiteLanguages = arrayOfEnabledLanguages.map((lang) => {
    return lang
  })

  // Make a list of languages acrtive in the profile
  let languagesInCurrentLanguageProfile = []
  const allLangProfiles = allLanguageProfiles.allLanguageProfiles
  if (allLanguageProfiles) {
    for (const profile of allLangProfiles) {
      languagesInCurrentLanguageProfile.push(profile.language)
    }
  }

  // Create a list with disable / enabled values
  const enabledAndDisabledLanguages = allSiteLanguages.map((lang) => {
    return {
      name: lang,
      dasabled:
        languagesInCurrentLanguageProfile.indexOf(lang) > -1 ? false : true,
    }
  })

  const ActiveLangComponent = enabledAndDisabledLanguages.map((lang, index) => {
    return (
      <Label3
        key={index}
        color={lang.dasabled ? 'red' : 'green'}
        overrides={{
          Block: { style: { display: 'inline-block', padding: '0 5px' } },
        }}
      >
        {lang.name.toUpperCase()}
      </Label3>
    )
  })

  return <Row>Enabled: {ActiveLangComponent}</Row>
}

export default LanguagesEnabledInProfile
