import React from 'react'
import Select from './Select'
import { Badge } from '@mui/material'

// We need to extend a drwaer select, due to Z-Index...
const CustomDrawerSelect = React.forwardRef((props: any, ref) => {
  return (
    <Select
      inputRef={ref}
      overrides={{
        Placeholder: {
          style: ({ $theme }) => {
            return {
              ...$theme.typography.fontBold14,
              color: $theme.colors.textNormal,
            }
          },
        },
        DropdownListItem: {
          style: ({ $theme }) => {
            return {
              ...$theme.typography.fontBold14,
              color: $theme.colors.textNormal,
            }
          },
        },
        OptionContent: {
          style: ({ $theme, $selected }) => {
            return {
              ...$theme.typography.fontBold14,
              color: $selected
                ? $theme.colors.textDark
                : $theme.colors.textNormal,
            }
          },
        },
        SingleValue: {
          style: ({ $theme }) => {
            return {
              ...$theme.typography.fontBold14,
              color: $theme.colors.textNormal,
              // textOverflow: 'ellipsis',
              maxWidth: '300px',
            }
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: { zIndex: 150 },
              },
            },
          },
        },
        Tag: {
          props: {
            overrides: {
              Text: {
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                },
              },
            },
          },
        },

        Dropdown: {
          style: {
            maxHeight: '300px', // Set the max height here
          },
        },
      }}
      {...props}
    />
  )
})

export default CustomDrawerSelect
