import { gql } from '@apollo/client'

export const GET_CUSTOMER_GROUPS = gql`
  query GET_CUSTOMER_GROUPS(
    $searchTerm: String
    # $searchTermInteger: Int
    # $language: EnumLanguageFilter
    $take: Int
    $skip: Int
    $orderBy: [CustomerGroupOrderByWithRelationInput!]
  ) {
    findManyCustomerGroupCount(
      where: { OR: [{ name: { mode: insensitive, contains: $searchTerm } }] }
    )

    findManyCustomerGroup(
      orderBy: $orderBy
      where: { OR: [{ name: { mode: insensitive, contains: $searchTerm } }] }
      take: $take
      skip: $skip
    ) {
      # ----- The customer
      id
      createdAt
      name
      fabric_palettes {
        name
        id
      }
      _count {
        Customer
        fabric_palettes
      }
    }
  }
`

export const DELETE_SINGLE_CUSTOMER_GROUP = gql`
  mutation DELETE_SINGLE_CUSTOMER_GROUP($id: Int!) {
    deleteOneCustomerGroup(where: { id: $id }) {
      id
    }
  }
`

export const CORE_CUSTOMER_GROUP_DATA = gql`
  fragment CoreCustomerGroupData on CustomerGroup {
    id
    name
    fabric_palettes {
      id
    }
    additional_components {
      id
      additionalComponent {
        additional_component_profiles(where: $where) {
          name
          language
        }
      }
    }
  }
`

//  TODO ======
export const CREATE_CUSTOMER_GROUP = gql`
  ${CORE_CUSTOMER_GROUP_DATA}
  mutation CREATE_CUSTOMER_GROUP($name: String!) {
    createCustomerGroup(name: $name) {
      ...CoreCustomerGroupData
    }
  }
`

export const CUSTOMER_GROUP_DATA = gql`
  fragment CustomerGroupData on CustomerGroup {
    id
    name
    fabric_palettes {
      id
    }
    additional_components {
      id
      additionalComponent {
        id
        additionalComponentGroupId
        additional_component_profiles(
          where: { language: { equals: $language } }
        ) {
          name
          language
        }
      }
    }
  }
`

export const UPDATE_CUSTOMER_GROUP = gql`
  ${CUSTOMER_GROUP_DATA}
  mutation UPDATE_CUSTOMER_GROUP(
    $id: Int!
    $language: Language
    $additional_components: [Int]
    $name: String!
    $fabric_palettes: [Int]
  ) {
    updateCustomerGroup(
      where: { id: $id }
      name: $name
      fabric_palettes: $fabric_palettes
      additional_components: $additional_components
    ) {
      ...CustomerGroupData
    }
  }
`

export const GET_CUSTOMER_GROUP = gql`
  ${CUSTOMER_GROUP_DATA}
  query GET_CUSTOMER_GROUP($id: Int!, $language: Language) {
    findUniqueCustomerGroup(where: { id: $id }) {
      ...CustomerGroupData
    }
  }
`
