import React, { useCallback, useEffect, useState } from 'react'
import { styled, withStyle, createThemedUseStyletron } from 'baseui'
import { Grid, Row, Col } from 'components/FlexBox/FlexBox'
import Select from 'components/Select/Select'
import Input from 'components/Input/Input'
import { useQuery, gql } from '@apollo/client'
import { Wrapper, Header, Heading } from 'components/Wrapper.style'
import ThemeButton from 'components/Button/Button'
// import {Button} from 'baseui/button';
import { useDrawerDispatch } from 'context/DrawerContext'
import { Check, Delete, Plus, Spinner } from 'baseui/icon'
import useDebounce from '../../utils/hooks/debounce'
import { OrganizationMembersTable } from './OrganizationMembersTable'

// TODO Update this query to when prisma rollouts _count functionality...
// This will serch through all color profiles (no matter the language)

type CustomThemeT = { red400: string; textNormal: string; colors: any }
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>()

export default function OrganizationMembersPage() {
  const [searchTerm, setSearchTerm] = useState('')
  // Controlling DRAWER (The edit form) dispatch from here...
  const dispatch = useDrawerDispatch()

  // ======= FORM Drawer CONTROLS ==========
  const openDrawerNewForm = useCallback(
    () =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'ORGANIZATION_MEMBER_FORM',
        data: {},
      }),
    [dispatch],
  )

  const openCustomerUpdateForm = useCallback(
    (userId) => {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'ORGANIZATION_MEMBER_FORM',
        data: {
          userId,
        },
      })
    },
    [dispatch],
  )
  // ======= FORM Drawer CONTROLS (above) ==========

  // TODO add new order, when product select is finished
  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 5,
              boxShadow: '0 0 8px rgba(0, 0 ,0, 0.1)',
            }}
          >
            <Col md={3} xs={12}>
              <Heading>Organization Members</Heading>
            </Col>

            <Col md={9} xs={12}>
              <Row>
                <Col md={8} xs={12}>
                  <SearchInput setSearchTerm={setSearchTerm} />
                </Col>

                <Col md={4} xs={12}>
                  <ThemeButton
                    style={{ width: '100%' }}
                    startEnhancer={() => <Plus size={20} />}
                    onClick={openDrawerNewForm}
                    // disabled
                  >
                    Create
                  </ThemeButton>
                </Col>
              </Row>
            </Col>
          </Header>

          <Wrapper
            style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)', marginTop: 20 }}
          >
            <OrganizationMembersTable
              searchTerm={searchTerm}
              updateRow={openCustomerUpdateForm}
            />
          </Wrapper>
        </Col>
      </Row>
    </Grid>
  )
}

const SearchInput = ({ setSearchTerm }) => {
  const [searchString, setSearchString] = useState('')
  const debouncedSearchTerm = useDebounce(searchString, 500)

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearchTerm(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, setSearchTerm])

  const handleEnter = (e) => {
    // Handling Enter Clicks
    if (e.charCode === 13) {
      setSearchTerm(searchString)
    }
  }

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setSearchString(e.target.value)
      setSearchTerm(e.target.value)
    } else {
      setSearchString(e.target.value)
    }
  }

  return (
    <Input
      value={searchString}
      onChange={handleInputChange}
      placeholder="Search by name"
      clearable
      clearOnEscape
      onKeyPress={handleEnter}
    />
  )
}
