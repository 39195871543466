import React from 'react'
import { Controller } from 'react-hook-form'
import { Row, Col } from 'components/FlexBox/FlexBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'

import {
  FieldDetails,
  Form,
  RowTitle,
} from 'containers/DrawerItems/DrawerItems.style'

import Input from 'components/Input/Input'
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox'

export const getSetting = (settingsArray, settingName) =>
  settingsArray.find((setting) => setting.name === settingName)

const SettingField = ({ setting, control }) => {
  if (!setting) {
    return null
  }
  return (
    <Row>
      <Col lg={12} sm={12}>
        <FormFields>
          {setting.type !== 'bool' && <FormLabel>{setting.title}</FormLabel>}
          <Controller
            name={setting.name}
            control={control}
            defaultValue={setting.value}
            render={({ field }) => {
              if (setting.type === 'bool') {
                return (
                  <Checkbox
                    checked={field.value}
                    checkmarkType={STYLE_TYPE.default}
                    onChange={field.onChange}
                    labelPlacement={LABEL_PLACEMENT.right}
                    overrides={{
                      Root: {
                        style: {
                          float: 'right',
                        },
                      },
                    }}
                  >
                    {setting.title}
                  </Checkbox>
                )
              }

              return (
                <Input
                  {...field}
                  type={setting.type === 'number' ? 'number' : 'text'}
                />
              )
            }}
          />
          {setting.description && (
            <FieldDetails style={{ padding: 0 }}>
              {setting.description}
            </FieldDetails>
          )}
        </FormFields>
      </Col>
    </Row>
  )
}

export default SettingField
