import React, { useEffect, useState } from 'react'
import { Label4 } from 'baseui/typography'
import DrawerBox from 'components/DrawerBox/DrawerBox'
import { Col, Row } from 'components/FlexBox/FlexBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'
import Input from 'components/Input/Input'
import LanguageSwitcherProfile from 'components/LanguageSwitcher/LanguageSwitcherProfile'
import MetaInformationInput from 'components/MetaInformation/MetaInformationInput'
import LanguagesEnabledInProfile from './Helpers/LanguagesEnabledInProfile'
import { Textarea } from 'components/Textarea/Textarea'
import { FieldDetails } from 'containers/DrawerItems/DrawerItems.style'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { removeKey } from 'utils'
import { Button } from 'baseui/button'
import { ProgressBar } from 'baseui/progress-bar'
import { SiteLanguageVar } from 'lib/reactiveVars'
import Uploader from 'components/Uploader/Uploader'

const PopUpProfileInput = ({
  selectedProfile,
  allLanguageProfiles,
  lang,
  setLang,
  currentProfileOnChange,
  errors,
  ...props
}) => {
  // Relative to the model / the state should be changed...
  const [id, setId] = useState(null)
  const [title, setTitle] = useState('')
  const [buttonText, setButotnText] = useState('')
  const [image, setImage] = useState([])

  useEffect(() => {
    // If selected profile changes, update curren state...
    setId(selectedProfile?.id ?? null)
    setTitle(selectedProfile?.title ?? '')
    setButotnText(selectedProfile?.button_text ?? '')
    setImage(selectedProfile?.image ?? [])
  }, [selectedProfile])

  useEffect(() => {
    // Modify tge current profile to the correct form before sending back!
    const currentProfile = {
      id,
      title: title,
      button_text: buttonText,
      language: lang,
      image: image,
    }

    currentProfileOnChange(currentProfile)
  }, [title, buttonText, image[0]])

  return (
    <Row>
      <Col lg={12} sm={12}>
        <DrawerBox sameHeight>
          <Row>
            <Col lg={9} sm={6}>
              <FormLabel>Information ({`${lang.toUpperCase()}`})</FormLabel>
              <LanguagesEnabledInProfile
                allLanguageProfiles={allLanguageProfiles}
              />
            </Col>
            <Col lg={3} sm={6} style={{ display: 'flex', textAlign: 'right' }}>
              <>
                <LanguageSwitcherProfile
                  currentLanguage={lang}
                  setCurrentLanguage={setLang}
                />
              </>
            </Col>
          </Row>

          {/* {loading && <ProgressBar infinite={true} value={100} />} */}
          {errors && <Label4 color="red">Error: {errors.message}</Label4>}

          <Row
            style={{
              paddingTop: 20,
              marginTop: 20,
              borderTop: '1px solid #eeeeee',
            }}
          >
            <Uploader onChange={setImage} multiple={false} images={image} />

            <Col lg={12} sm={12}>
              <FormFields>
                <FormLabel>Title</FormLabel>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormFields>
              <FormFields>
                <FormLabel>Button Text</FormLabel>
                <Input
                  value={buttonText}
                  onChange={(e) => setButotnText(e.target.value)}
                />
              </FormFields>
            </Col>
          </Row>
        </DrawerBox>
      </Col>
    </Row>
  )
}

export default PopUpProfileInput
