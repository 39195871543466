import { createCtx } from "./create-context";

const initialState = {
  isOpen: false,
  drawerComponent: null,
  data: null,
  onChange: null,
};
type State = typeof initialState;
type Action = any;
function reducer(state: State, action: Action) {
  switch (action.type) {
    case "OPEN_LEFT_DRAWER":
      return {
        ...state,
        isOpen: true,
        drawerComponent: action.drawerComponent,
        data: action.data,
        onChange: action.onChange,
      };
    case "CLOSE_LEFT_DRAWER":
      return {
        ...state,
        isOpen: false,
        drawerComponent: null,
        data: null,
        onChange: null,
      };
    default:
      return state;
  }
}
const [useDrawerLeftState, useDrawerLeftDispatch, DrawerLeftProvider] =
  createCtx(initialState, reducer);

export { useDrawerLeftState, useDrawerLeftDispatch, DrawerLeftProvider };
