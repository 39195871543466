import React, { useEffect, useState } from 'react'
import { Input as BaseInput, SIZE } from 'baseui/input'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import DrawerSelect from 'components/Select/DrawerSelect'
import { SiteLanguageVar } from 'lib/reactiveVars'
import { extractProfileByLanguage } from 'utils/languages'

// TODO Add create mew Manufacturer functionality....

export const GET_ROLES_FOR_SELECT = gql`
  query {
    __type(name: "Role") {
      name
      enumValues {
        name
      }
    }
  }
`

const RoleSelect = ({
  onChange,
  value,
  placeholder = null,
  disabled = false,
  ...props
}) => {
  // Value is either null Or id of parent category.
  const [optionValue, setOptionValue] = useState(null)
  // === Site language
  //const siteLanguage = useReactiveVar(SiteLanguageVar)
  // === GRAPHQL Query to get Languages
  const { loading, error, data } = useQuery(GET_ROLES_FOR_SELECT, {
    // -- Turning off, as referch queries are a lot more efficient
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (value && data && rolesOptions) {
      const selectedValue = rolesOptions.filter((option) => option.id === value)
      setOptionValue(selectedValue)
    }
  }, [value, data])

  if (loading) return <ProgressBar infinite={true} value={100} />
  if (error) return <p>Query Error! {error.message}</p>

  const rolesOptions = data.__type.enumValues
    .map((customerGroup) => {
      // Only return if there is a profile associated with it.
      return {
        id: customerGroup.name,
        name: customerGroup.name,
      }
    })
    .filter((item) => item !== undefined)

  const handleChange = (value) => {
    // console.log("Value ->", value);
    // Pass the value up (onChange)
    let selectedId = null
    if (value[0]) {
      selectedId = value[0].id
    }
    setOptionValue(value)
    onChange(selectedId)
  }

  return (
    <DrawerSelect
      options={rolesOptions}
      labelKey="name"
      valueKey="id"
      placeholder={placeholder ?? 'Select...'}
      onChange={(e) => handleChange(e.value)}
      value={optionValue}
      searchable={true}
      clearable={true}
      disabled={disabled}
    />
  )
}

export default RoleSelect
