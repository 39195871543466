import { gql } from '@apollo/client'

export const GET_USERS = gql`
  query GET_USERS {
    findManyUser {
      role
      email
      createdAt
      updatedAt
      id
      profile {
        name
        surname
      }
    }
  }
`

export const DELETE_SINGLE_USER = gql`
  mutation DELETE_SINGLE_USER($id: Int!) {
    deleteOneUser(where: { id: $id }) {
      id
    }
  }
`

export const CORE_USER = gql`
  fragment CoreUserData on User {
    email
    id
    role
    profile {
      name
      surname
    }
  }
`

//  TODO ======
export const CREATE_USER = gql`
  ${CORE_USER}
  mutation CREATE_USER(
    $email: String!
    $password: String!
    $role: Role!
    $name: String
    $surname: String
  ) {
    createUser(
      email: $email
      password: $password
      role: $role
      name: $name
      surname: $surname
    ) {
      ...CoreUserData
    }
  }
`

export const UPDATE_USER = gql`
  ${CORE_USER}
  mutation UPDATE_USER(
    $email: String!
    $password: String!
    $role: Role!
    $name: String
    $surname: String
  ) {
    updateUser(
      email: $email
      password: $password
      role: $role
      name: $name
      surname: $surname
    ) {
      ...CoreUserData
    }
  }
`

export const GET_USER = gql`
  ${CORE_USER}
  query GET_USER($id: Int!) {
    findUniqueUser(where: { id: $id }) {
      ...CoreUserData
    }
  }
`

export const GET_USER_PROFILE = gql`
  query GET_USER_PROFILE($email: String!) {
    findUniqueUser(where: { email: $email }) {
      email
      id
      role
      profile {
        name
        surname
      }
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  mutation UPDATE_USER_PROFILE(
    $email: String!
    $name: String
    $surname: String
    $old_password: String!
    $new_password: String!
    $confirm_password: String!
  ) {
    updateMyProfile(
      email: $email
      name: $name
      surname: $surname
      old_password: $old_password
      new_password: $new_password
      confirm_password: $confirm_password
    ) {
      email
      id
      role
      profile {
        name
        surname
      }
    }
  }
`
