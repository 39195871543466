import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDrawerDispatch, useDrawerState } from 'context/DrawerContext'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import Button, { KIND } from 'components/Button/Button'
import {
  ButtonGroup,
  DrawerTitle,
  DrawerTitleWrapper,
  FieldDetails,
  Form,
  RowTitle,
} from 'containers/DrawerItems/DrawerItems.style'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { Col, Flex, Grid, Row } from 'components/FlexBox/FlexBox'
import DrawerBox from 'components/DrawerBox/DrawerBox'
import { FormFields, FormLabel } from 'components/FormFields/FormFields'
import Input from 'components/Input/Input'
import { Label4 } from 'baseui/typography'
import { Scrollbars } from 'react-custom-scrollbars'
import { InLineLoader } from 'components/InlineLoader/InlineLoader'
import { ChromePicker } from 'react-color'
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox'
import { ColorCircleBig } from 'containers/Colors/Colors.style'
import { ColorPicker } from 'components/ColorGradientPicker'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SiteLanguageVar } from 'lib/reactiveVars'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { removeKey } from 'utils'
import { DURATION, SnackbarProvider, useSnackbar } from 'baseui/snackbar'
import { Check } from 'baseui/icon'
import {
  CREATE_USER,
  GET_USER,
  GET_USER_PROFILE,
  UPDATE_USER,
  UPDATE_USER_PROFILE,
} from './queries'
import { set } from 'date-fns'
import FabricCatoriesTable from './FabricCategoriesTable'
import { useDrawerLeftDispatch } from 'context/DrawerLeftContext'
import RoleSelect from 'components/RoleSelect/RoleSelect'
import { profile } from 'console'
import { Header, Heading, Wrapper } from 'components/Wrapper.style'
import { AuthContext } from 'context/auth'

// TODO DO SOME VALIDATION
// Validation rules....
const schema = yup.object().shape({
  //  ============ PROFILE VALIDATION =======
  //Email and passwrod are requite
  // email: yup.string().required(),
  // password: yup.string().required(),

  email: yup.string().required(),
  old_password: yup.string().when('new_password', {
    is: (val) => val && val.length > 0,
    then: yup
      .string()
      .required('Old password is required when new password is provided'),
  }),
  new_password: yup.string(),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
  role: yup.string().required(),

  name: yup.string(),
  surname: yup.string(),

  // surname: yup.string().required(),
})

//* ===================================== UPDATE COLOR  =========================================
// here we intercept data from context, we get the category ID (either from parent or child category) from it and then we populate context with more data from the query result. After that we render CategoryUpdateForm that will then have all the data it needs from context
type Props = any

const showSuccessNotification = (enqueue, message) =>
  enqueue(
    {
      message,
      startEnhancer: ({ size }) => <Check size={size} />,
      overrides: {
        Message: {
          style: ({ $theme }) => ({
            color: $theme.colors.lightGreen,
          }),
        },
        StartEnhancerContainer: {
          style: ({ $theme }) => ({
            color: $theme.colors.lightGreen,
          }),
        },
      },
    },
    DURATION.short,
  )
const showErrorNotification = (enqueue, message) =>
  enqueue(
    {
      message,
      startEnhancer: ({ size }) => <Check size={size} />,
      overrides: {
        Message: {
          style: ({ $theme }) => ({
            color: $theme.colors.red400,
          }),
        },
        StartEnhancerContainer: {
          style: ({ $theme }) => ({
            color: $theme.colors.red400,
          }),
        },
      },
    },
    DURATION.short,
  )

const MemberProfileForm: React.FC<Props> = () => {
  const auth = useContext(AuthContext) //const { userId } = useDrawerState('data')

  const [email, setEmail] = useState(auth.user.email)

  const { enqueue } = useSnackbar()

  //* ------------------------ DRAWER CONTEXT
  const dispatch = useDrawerDispatch()
  // ===== CLOSE DRAWER CALLBACK
  const closeDrawer = useCallback(
    () => dispatch({ type: 'CLOSE_DRAWER' }),
    [dispatch],
  )

  const [fetchUserProfile, { loading, error, data: queryData }] = useLazyQuery(
    GET_USER_PROFILE,
    {
      variables: {
        email,
      },
    },
  )

  useEffect(() => {
    if (email) {
      console.log('Fetch data for user')
      fetchUserProfile()
    }
  }, [email])

  useEffect(() => {
    // Update Hex in state, once Graphql is finished
    const user = queryData?.findUniqueUser

    console.log('User data:', queryData)

    if (user) {
      setValue('email', user.email)
      setValue('role', user.role)

      setValue('name', user.profile?.name ?? '')
      setValue('surname', user.profile?.surname ?? '')
    }
  }, [queryData]) // We need to include called value (to refresh after mutation)

  //* ==== Mutation to edita data in database

  const [
    updateFabricPalette,
    {
      data: updateMutationData,
      called: updateCalled,
      loading: updateMutationLoading,
      error: updateMutationError,
    },
  ] = useMutation(UPDATE_USER_PROFILE, {
    onError: (e) => {
      // For mutation errors...
      console.log('Mutation error!', e)
      console.log('Extracted error!', e.graphQLErrors)
      showErrorNotification(enqueue, 'Error!')
    },
    onCompleted: (e) => {
      setValue('old_password', '')
      setValue('new_password', '')
      setValue('confirm_password', '')
      showSuccessNotification(enqueue, 'Member Updated!')
    },
    refetchQueries: ['GET_USERS'],
  })

  //=========== REACT HOOOK FORM ============
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    control,
  } = useForm({
    resolver: yupResolver(schema),

    defaultValues: {
      email: '',
      old_password: '',
      new_password: '',
      confirm_password: '',
      role: '',
      name: '',
      surname: '',
    },
  })

  // =================================

  const onSubmit = (data) => {
    console.log('Submitting -->', data)
    // Modify form data here =============

    //! Typename removal messes the Date...
    // Remove __typename keys from the query.
    const modifiedData = removeKey(data, '__typename')

    const variables = {
      email: modifiedData.email,
      old_password: modifiedData.old_password,
      new_password: modifiedData.new_password,
      confirm_password: modifiedData.confirm_password,
      name: modifiedData.name,
      surname: modifiedData.surname,
    }

    // if (id) {
    console.log('Update', variables)
    updateFabricPalette({
      variables: {
        ...variables,
        //where: { email: email },
      },
    })
    // }
  }

  const userDelete = () => {
    console.log('Delete user')
  }

  // <>
  //   <DrawerTitleWrapper>
  //     <DrawerTitle>{id ? 'Update ' : 'Create a new '}Member</DrawerTitle>
  //     {Object.keys(errors).length > 0 ? (
  //       <Label4 color="red">There are ERRORS! (scroll up or down)</Label4>
  //     ) : null}
  //   </DrawerTitleWrapper>

  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 5,
              boxShadow: '0 0 8px rgba(0, 0 ,0, 0.1)',
            }}
          >
            <Col md={3} xs={12}>
              <Heading>My Profile</Heading>
            </Col>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form onSubmit={handleSubmit(onSubmit)} style={{ height: '1000px' }}>
            <Scrollbars
              autoHide
              renderView={(props) => (
                <div
                  {...props}
                  style={{ ...props.style, overflowX: 'hidden' }}
                />
              )}
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  style={{ display: 'none' }}
                  className="track-horizontal"
                />
              )}
            >
              <Row>
                <Col lg={3} sm={12}>
                  <DrawerBox sameHeight noBackground>
                    <FormLabel>Login information</FormLabel>
                    <FieldDetails style={{ padding: 0 }}>
                      Should be used for main Member indentification
                    </FieldDetails>
                  </DrawerBox>
                </Col>
                <Col lg={9} sm={12}>
                  <DrawerBox sameHeight>
                    <Row>
                      <Col lg={6} sm={12}>
                        <FormFields>
                          <FormLabel>Email</FormLabel>
                          <Input
                            {...register('email')}
                            error={errors.email ? true : false}
                            disabled
                          />
                          {errors.email && (
                            <Label4 color="red">{errors.email.message}</Label4>
                          )}
                        </FormFields>
                      </Col>
                    </Row>
                  </DrawerBox>
                </Col>
              </Row>
              <Row>
                <Col lg={3} sm={12}>
                  <DrawerBox sameHeight noBackground>
                    <FormLabel>Change Password</FormLabel>
                    <FieldDetails style={{ padding: 0 }}>
                      Leam empty if you don't want to change password
                    </FieldDetails>
                  </DrawerBox>
                </Col>
                <Col lg={9} sm={12}>
                  <DrawerBox sameHeight>
                    <Row>
                      <Col lg={6} sm={12}>
                        <FormFields>
                          <FormLabel>Old password</FormLabel>
                          <Input
                            {...register('old_password')}
                            error={errors.email ? true : false}
                            type="password"
                          />
                          {errors.old_password && (
                            <Label4 color="red">
                              {errors.old_password.message}
                            </Label4>
                          )}
                        </FormFields>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12}>
                        <FormFields>
                          <FormLabel>New password</FormLabel>
                          <Input
                            {...register('new_password')}
                            error={errors.new_password ? true : false}
                            type="password"
                          />
                          {errors.new_password && (
                            <Label4 color="red">
                              {errors.new_password.message}
                            </Label4>
                          )}
                        </FormFields>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12}>
                        <FormFields>
                          <FormLabel>Confirm Password</FormLabel>
                          <Input
                            {...register('confirm_password')}
                            error={errors.confirm_password ? true : false}
                            type="password"
                          />
                          {errors.confirm_password && (
                            <Label4 color="red">
                              {errors.confirm_password.message}
                            </Label4>
                          )}
                        </FormFields>
                      </Col>
                    </Row>
                  </DrawerBox>
                </Col>
              </Row>
              <Row>
                <Col lg={3} sm={12}>
                  <DrawerBox sameHeight noBackground>
                    <FormLabel>Member Profile</FormLabel>
                    <FieldDetails style={{ padding: 0 }}>
                      Optional information
                    </FieldDetails>
                  </DrawerBox>
                </Col>
                <Col lg={9} sm={12}>
                  <DrawerBox sameHeight>
                    <Row>
                      <Col lg={6} sm={12}>
                        <FormFields>
                          <FormLabel>Name</FormLabel>
                          <Input
                            {...register('name')}
                            error={errors.name ? true : false}
                          />
                          {/* {errors.email && (
                        <Label4 color="red">{errors.email.message}</Label4>
                      )} */}
                        </FormFields>
                      </Col>
                      <Col lg={6} sm={12}>
                        <FormFields>
                          <FormLabel>Surname</FormLabel>
                          <Input
                            {...register('surname')}
                            error={errors.surname ? true : false}
                            autoComplete="off"
                          />
                          {/* {errors.password && (
                        <Label4 color="red">{errors.password.message}</Label4>
                      )} */}
                        </FormFields>
                      </Col>
                    </Row>
                  </DrawerBox>
                </Col>
              </Row>
              <Row>
                <Col lg={3} sm={12}>
                  <DrawerBox sameHeight noBackground>
                    <FormLabel>Role</FormLabel>
                  </DrawerBox>
                </Col>
                <Col lg={9} sm={12}>
                  <DrawerBox sameHeight>
                    <FormFields>
                      <Controller
                        name="role"
                        control={control}
                        rules={{
                          required: false,
                          shouldUnregister: true,
                        }}
                        render={({ field }) => {
                          return (
                            <RoleSelect
                              onChange={field.onChange}
                              value={field.value}
                              disabled
                            />
                          )
                        }}
                      />
                    </FormFields>
                    {errors.role && (
                      <Label4 color="red">{errors.role.message}</Label4>
                    )}
                  </DrawerBox>
                </Col>
              </Row>

              {/* ============== SUBMIT BUTTONS ============== */}
            </Scrollbars>

            <ButtonGroup style={{ zIndex: 10 }}>
              <Button
                kind={KIND.minimal}
                onClick={closeDrawer}
                disabled={updateMutationLoading}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      width: '50%',
                      borderTopLeftRadius: '3px',
                      borderTopRightRadius: '3px',
                      borderBottomRightRadius: '3px',
                      borderBottomLeftRadius: '3px',
                      marginRight: '15px',
                      color: $theme.colors.red400,
                    }),
                  },
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                disabled={updateMutationLoading}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      width: '50%',
                      borderTopLeftRadius: '3px',
                      borderTopRightRadius: '3px',
                      borderBottomRightRadius: '3px',
                      borderBottomLeftRadius: '3px',
                    }),
                  },
                }}
              >
                {'Update'}
              </Button>
            </ButtonGroup>
          </Form>
        </Col>
      </Row>
    </Grid>
  )
}

export default MemberProfileForm
