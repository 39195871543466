import React from 'react'
import { Select } from 'baseui/select'
import { CaretDownIcon } from 'assets/icons/CaretDownIcon'

export const getContainerFontStyle = ({ $theme }) => {
  return $theme.typography.fontBold14
}

export default function CustomSelect(props) {
  return (
    <Select
      // filterOptions={(value) => console.log("filterOptions value ---> ", value)}
      overrides={{
        SelectArrow: () => {
          return <CaretDownIcon />
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: { zIndex: 1, maxHeight: '200px' },
              },
            },
          },
        },
        Placeholder: {
          style: ({ $theme }) => ({
            color: $theme.colors.textDark,
            ...getContainerFontStyle({ $theme }),
          }),
        },
        SingleValue: {
          style: ({ $theme }) => ({
            ...getContainerFontStyle({ $theme }),
            color: $theme.colors.textDark,
            lineHeight: '1.5',
          }),
        },
        DropdownListItem: {
          style: (props) => {
            return {
              fontSize: '14px',
              fontWeight: '700',
              color: props.$theme.colors.textDark,
              paddingLeft: '24px',
            }
          },
        },
        OptionContent: {
          style: ({ $theme, $selected }) => {
            return {
              ...$theme.typography.fontBold14,
              color: $selected
                ? $theme.colors.textDark
                : $theme.colors.textNormal,
            }
          },
        },
        Dropdown: {
          style: {
            maxHeight: '200px', // Set the max height here
          },
        },
        DropdownOption: {
          style: ({ $theme }) => ({
            fontSize: '24px',
            fontWeight: '700',
            color: $theme.colors.textDark,
          }),
        },
      }}
      {...props}
    />
  )
}
